<template>
    <editor v-model="comp.contents" @change="comp.update" :automatic_uploads="true" :api-key="comp.tinykey" :init="comp.init" id="reum_editor" class="reum-editor"></editor>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive, watch } from 'vue'
import Editor from '@tinymce/tinymce-vue'
import axios from '@/plugins/axios.js';


export default {
    name: 'CarrotEditor',
    components: {
        Editor
    },
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        etype: {
            type: String,
            default: "temp",
        },
    },
    emits: [ 'update:modelValue' ],
    setup(props, {emit}) {
        const comp = reactive({
            tinykey  : 'ebfom6nnggeuhv60oeeqk5ujk37siraqxo97hjykqt5hgkrz',
            etype    : "",
            contents : "",

            init : {
                selector: '#reum_editor',  // change this value according to your HTML
                menubar: false,
                // plugins: 'image code',
                plugins: [
                    'advlist autolink lists link image code charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                // toolbar: 'undo redo | link image | code',
                toolbar: 'undo redo | fontsizeselect | bold italic forecolor backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | link image | code',
                image_title: true,
                automatic_uploads: true,
                file_picker_types: 'image',
                file_picker_callback: function (cb) {
                    let input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');
                    input.onchange = function () {
                        let file = this.files[0];

                        if( file.name ){
                            var formData = new FormData();
                            formData.append("etype", comp.etype);
                            formData.append("upfile", file);
                            axios.post("/rest/component/uploads", formData, {
                                headers: {
                                'Content-Type': 'multipart/form-data'
                                }
                            }).then((res)=>{
                                if( res.data.err == 0 ){
                                    cb(res.data.fileuri, { title: file.name });
                                } else {
                                    alert(res.data.err_msg);
                                }
                            });
                        }
                    };

                    input.click();
                }
            },

            update: () => {
                emit('update:modelValue', comp.contents);
            }
        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal){
                comp.contents  = nVal;
            }
        });

        onMounted(() => {
            // Mounted
            comp.contents  = props.modelValue;
            comp.etype     = props.etype;
        });

        return { comp };
    }
}

</script>
<style lang="scss" scoped>
.reum-editor {
    width: 100%; height: 100%;
}
</style>
<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="전략마케팅 처리현황">
            - Inbound (고객으로부터 온 전화를 받았을 때)<br>
            - Outbound (고객 혹은 잠재고객에게 능동적으로 전화를 걸었을 때)
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 분류</th>
                                <td colspan="3">
                                    <select v-model="mod.info.category" class="w-200px">
                                        <option value="">분류 전체</option>
                                        <option value="INBOUND">Inbound</option>
                                        <option value="OUTBOUND">Outbound</option>
                                        <option value="B2G">B2G(나라장터)</option>
                                        <option value="PARTNER">제휴관련</option>
                                        <option value="ETC">기타</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 회사명</th>
                                <td>
                                    <p v-if="!mod.is_company_edit" class="float-left">{{ mod.info.companyname }}</p><button v-if="!mod.is_company_edit" @click="mod.companyMod" class="btn-default float-left ml-10">회사검색</button>
                                    <CarrotCompany v-if="mod.is_company_edit" v-model:companyname.sync="mod.info.companyname" v-model="mod.info.idx_company" class="float-left w-290px"></CarrotCompany>
                                    <button v-if="mod.is_company_edit" @click="mod.companyReg" class="btn-default float-left ml-10">신규등록</button>
                                </td>
                                <th><span class="txt-red">*</span> 담당자</th>
                                <td>
                                    <input type="text" v-model.trim="mod.info.manager" class="w-300px">
                                </td>
                            </tr>
                            <tr>
                                <th>연락처</th>
                                <td>
                                    <input type="text" v-model.trim="mod.info.phone" class="w-300px">
                                </td>
                                <th>E-mail</th>
                                <td>
                                    <input type="text" v-model.trim="mod.info.email" class="w-300px">
                                </td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td colspan="3">
                                    <carrot-address v-model:zipcode.sync="mod.info.zipcode" v-model:address.sync="mod.info.addr"></carrot-address>
                                    <input type="text" v-model.trim="mod.info.addr_sub" class="w-100per mt-5" placeholder="상세주소를 입력하세요.">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>유입경로<br>(캐럿 매니저가 직접 입력 시<br>유입 경로 파악 부탁드립니다.)</th> 
                                <td colspan="3">
                                    <label><input type="radio" v-model="mod.info.enterPath" value="캐럿 담당자 미팅"><span class="ml-5 mr-20"> 캐럿 담당자 미팅</span></label>
                                    <label><input type="radio" v-model="mod.info.enterPath" value="캐럿 컨퍼런스 및 설명회 등 모임"><span class="ml-5 mr-20"> 캐럿 컨퍼런스 및 설명회 등 모임</span></label>
                                    <label><input type="radio" v-model="mod.info.enterPath" value="온라인 홍보 (HR캐스터, 이메일 홍보, 인스타그램, 유튜브, 블로그 등)"><span class="ml-5 mr-20"> 온라인 홍보 (HR캐스터, 이메일 홍보, 인스타그램, 유튜브, 블로그 등)</span></label>
                                    <br>
                                    <label><input type="radio" v-model="mod.info.enterPath" value="인터넷 검색"><span class="ml-5 mr-20"> 인터넷 검색</span></label>
                                    <label><input type="radio" v-model="mod.info.enterPath" value="캐럿글로벌 종합안내서 책자 및 사보 등 자료"><span class="ml-5 mr-20"> 캐럿글로벌 종합안내서 책자 및 사보 등 자료</span></label>
                                    <label><input type="radio" v-model="mod.info.enterPath" value="타사 담당자 및 전임자 등 지인 소개"><span class="ml-5 mr-20"> 타사 담당자 및 전임자 등 지인 소개</span></label>
                                    <br>
                                    <label>
                                            <input type="radio" v-model="mod.info.enterPath" value="기타 : 직접 입력">
                                            <span class="ml-5 mr-20"> 기타 : 직접 입력</span>
                                            <input v-if="mod.info.enterPath === '기타 : 직접 입력'" v-model="mod.info.enterPathText" type="text" class="w-50per">                                        
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>과정 종류</th> 
                                <td colspan="3">
                                    <label><input type="radio" v-model="mod.info.program" value="어학"><span class="ml-5 mr-20"> 어학</span></label>
                                    <label><input type="radio" v-model="mod.info.program" value="리더십"><span class="ml-5 mr-20"> 리더십</span></label>
                                    <label><input type="radio" v-model="mod.info.program" value="주재원,현채인"><span class="ml-5 mr-20"> 주재원,현채인</span></label>
                                    <label><input type="radio" v-model="mod.info.program" value="해외연수"><span class="ml-5 mr-20"> 해외연수</span></label>
                                    <label><input type="radio" v-model="mod.info.program" value="GHR"><span class="ml-5 mr-20"> GHR</span></label>
                                    <label><input type="radio" v-model="mod.info.program" value="전화화상"><span class="ml-5 mr-20"> 전화화상</span></label>
                                    <label><input type="radio" v-model="mod.info.program" value="에듀테크"><span class="ml-5 mr-20"> 에듀테크</span></label>
                                    <label><input type="radio" v-model="mod.info.program" value="밝음, 명상"><span class="ml-5 mr-20"> 밝음, 명상</span></label>
                                    <label><input type="radio" v-model="mod.info.program" value="전반적 컨설팅"><span class="ml-5 mr-20"> 전반적 컨설팅</span></label>
                                    <label><input type="radio" v-model="mod.info.program" value="기타"><span class="ml-5 mr-20"> 기타</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 내용</th>
                                <td colspan="3">
                                    <CarrotEditor v-model="mod.info.contents" class="w-100per h-300px"></CarrotEditor>
                                    <!-- <textarea v-model.trim="mod.info.contents" class="w-100per h-100px"></textarea> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button v-if=" mod.info.is_del_able=='Y'" @click="mod.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="mod.doSubmit" class="btn-default float-right mt-30">수정</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
// import Swal from 'sweetalert2'
import CarrotAddress from '@/components/common/CarrotAddress.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue'
import CarrotEditor from '@/components/common/CarrotEditor.vue'

export default {
    layout:"customerManagement",
    components: {
        CarrotCompany,
        CarrotAddress,
        CarrotEditor
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const mod = reactive({
            is_loaded : false,

            is_company_edit : false,

            idx : 0,

            info : {
                category    : "",
                program     : "",
                idx_company : "",
                companyname : "",
                manager     : "",
                phone       : "",
                email       : "",
                zipcode     : "",
                addr        : "",
                addr_sub    : "",
                contents    : '',
                enterPath   : "",
                enterPathText: ""
            },

            companyMod : () => {
                mod.is_company_edit = true;
            },
            companyReg : () => {
                window.open(`/customerManagement/customerDBAdd`, '_blank');
            },

            doSubmit : () => {
                let params = {
                    idx      : mod.idx,
                    category : mod.info.category,
                    program : mod.info.program,
                    idx_company  : mod.info.idx_company,
                    companyname  : mod.info.companyname,
                    manager  : mod.info.manager,
                    phone    : mod.info.phone,
                    email    : mod.info.email,
                    zipcode  : mod.info.zipcode,
                    addr     : mod.info.addr,
                    addr_sub : mod.info.addr_sub,
                    contents : mod.info.contents,
                    enterPath: mod.info.enterPath,
                    enterPathText : mod.info.enterPathText,
                    enterUrl : mod.info.enterUrl,
                };

                if(mod.info.enterPath === '기타 : 직접 입력'){
                    if(mod.info.enterPathText === ""){
                        toast.error("유입경로: (기타)의 내용을 입력해주세요.");
                        return;
                    }
                }else{
                    params.enterPathText = '';
                }

                if( !params.category || !params.companyname || !params.manager ){
                    toast.error("필수 입력 항목을 입력해주세요.");
                    return;
                }

                // let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                // if ( params.email == '' || !re.test(params.email) ) {
                //     toast.error("올바른 이메일 주소를 입력하세요.");
                //     return;
                // }

                if( !params.contents ){
                    toast.error("필수 입력 항목을 입력해주세요.");
                    return;
                }

                axios.post("/rest/customermgr/modSMP", params).then((res) => {
                    if( res.data.err == 0 ){
                        router.push({
                            name : "customerManagement-statusSMPView-idx",
                            params : {
                                idx : res.data.idx
                            }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doCancel: () => {
                router.go(-1);
            },

            doSearch : () => {
                let params = {
                    idx : mod.idx
                };

                axios.get("/rest/customermgr/getSMPInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.info = res.data;

                        mod.is_loaded = true;
                        if(mod.info.idx_company && mod.info.idx_company < 1){
                            mod.is_company_edit = false;
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            mod.idx = route.params.idx?route.params.idx:0;

            if ( !mod.idx ) {
                router.back(-1);
            }

            mod.doSearch();
        });

        return {mod};
    }
}
</script>

<style lang="scss" scoped>
</style>
<template>
    <div>
        <div>
            <input type="text" class="w-100px mr-5" readonly v-model="comp.zipcode">
            <button class="btn-default h-30px mr-5" @click="comp.showAddress">주소검색</button>
            <input type="text" class="w-330px" readonly v-model="comp.address" @change="comp.update">
            <div style="clear:both"></div>
        </div>
        <div id="layer" class="address-layer" v-show="comp.is_show" style="display:none;" ref="addr_layer">
            <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnCloseLayer" class="btn-close" @click="comp.hideAddress" alt="닫기 버튼">
        </div>
    </div>
</template>
<script>
import { onMounted, reactive, watch, ref } from 'vue'

export default {
    name: 'CarrotAddress',
    components: {
    },
    props: {
        address: {
            type: String,
            default: "",
        },
        zipcode: {
            type: String,
            default: "",
        }
    },
    emits: [ 'update:address', 'update:zipcode' ],
    setup(props, { emit }) {
        const addr_layer = ref(null);

        const comp = reactive({
            address :"",
            zipcode : "",
            is_show : true,

            hideAddress: () => {
                // iframe을 넣은 element를 안보이게 한다.
                // addr_layer.style.display = 'none';
                comp.is_show = false;
            },

            showAddress: () => {
                new window.daum.Postcode({
                    oncomplete: function(data) {
                        // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

                        // 각 주소의 노출 규칙에 따라 주소를 조합한다.
                        // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
                        var addr = ''; // 주소 변수
                        var extraAddr = ''; // 참고항목 변수

                        //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
                        if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
                            addr = data.roadAddress;
                        } else { // 사용자가 지번 주소를 선택했을 경우(J)
                            addr = data.jibunAddress;
                        }

                        // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
                        if(data.userSelectedType === 'R'){
                            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                            if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                                extraAddr += data.bname;
                            }
                            // 건물명이 있고, 공동주택일 경우 추가한다.
                            if(data.buildingName !== '' && data.apartment === 'Y'){
                                extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                            }
                            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                            if(extraAddr !== ''){
                                extraAddr = ' (' + extraAddr + ')';
                            }
                            // 조합된 참고항목을 해당 필드에 넣는다.
                            // comp.address = extraAddr;
                        
                        } else {
                            // comp.address = '';
                        }

                        // 우편번호와 주소 정보를 해당 필드에 넣는다.
                        comp.zipcode = data.zonecode;
                        comp.address = addr + extraAddr;

                        // iframe을 넣은 element를 안보이게 한다.
                        // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
                        // addr_layer.style.display = 'none';
                        comp.is_show = false;
                    },
                    width : '100%',
                    height : '100%',
                    maxSuggestItems : 5
                }).embed(addr_layer.value);

                // iframe을 넣은 element를 보이게 한다.
                // addr_layer.style.display = 'block';
                comp.is_show = true;

                // iframe을 넣은 element의 위치를 화면의 가운데로 이동시킨다.
                comp.initLayerPosition();
            },

            initLayerPosition : () => {
                var width = 400; //우편번호서비스가 들어갈 element의 width
                var height = 500; //우편번호서비스가 들어갈 element의 height
                var borderWidth = 5; //샘플에서 사용하는 border의 두께

                // 위에서 선언한 값들을 실제 element에 넣는다.
                addr_layer.value.style.zIndex='999';
                addr_layer.value.style.width = width + 'px';
                addr_layer.value.style.height = height + 'px';
                addr_layer.value.style.border = borderWidth + 'px solid';
                // 실행되는 순간의 화면 너비와 높이 값을 가져와서 중앙에 뜰 수 있도록 위치를 계산한다.
                addr_layer.value.style.left = (((window.innerWidth || document.documentElement.clientWidth) - width)/2 - borderWidth) + 'px';
                addr_layer.value.style.top = (((window.innerHeight || document.documentElement.clientHeight) - height)/2 - borderWidth) + 'px';
            },

            update: () => {
                emit('update:zipcode', comp.zipcode);
                emit('update:address', comp.address);

                console.log(props.zipcode, props.address);
            }
        });

        watch(() => comp.zipcode, (nVal, oVal) => {
            if(nVal != oVal) {
                comp.zipcode = nVal;
                emit('update:zipcode', comp.zipcode);
            }
        });

        watch(() => comp.address, (nVal, oVal) => {
            if(nVal != oVal) {
                comp.address = nVal;
                emit('update:address', comp.address);
            }
        });

        watch(() => props.zipcode, (nVal, oVal) => {
            if(nVal != oVal) {
                comp.zipcode = nVal;
                // console.log(comp.zipcode);
            }
        });

        watch(() => props.address, (nVal, oVal) => {
            if(nVal != oVal) {
                comp.address = nVal;
                // console.log(comp.address);
            }
        });

        onMounted(() => {
            // Mounted
            const plugin = document.createElement("script");
            plugin.setAttribute( "src", "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js" );
            plugin.async = true;
            document.head.appendChild(plugin);

            comp.zipcode = props.zipcode;
            comp.address = props.address;


        });

        return {comp, addr_layer};
    }
}
</script>
<style lang="scss" scoped>
.btn-default {
    display: inline-block;
}
.address-layer {
    position:fixed;overflow:hidden;z-index:99;-webkit-overflow-scrolling:touch;
    .btn-close {
        cursor:pointer;position:absolute;right:-3px;top:-3px;z-index:99;
    }
}
</style>